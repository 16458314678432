<template>
	<section id="about" class="overflow-hidden">
		<v-row class="white" no-gutters>
			<v-col class="pa-5" cols="12" md="6">
				<base-bubble-1 style="transform: translate(5%, -5%)" />

				<base-heading class="info--text"> About Me </base-heading>

				<base-text class="mb-5">
					Lorem ipsum dolor sit amet, consectetur adipi<br />
					scin elit. Etiam vulputate augue vel felis gravida<br />
					porta. Lorem ipsum dolor sit amet.
				</base-text>

				<base-subheading class="info--text"> Skills </base-subheading>

				<base-text class="mb-5">
					Lorem ipsum dolor sit amet, consecte tur adipi scin e<br />
					lit. Etiam vulputate augu e vel felis gravida porta.
				</base-text>

				<v-alert outlined color="info">
					<v-row v-for="(skill, i) in skills" :key="i" style="color: #69a1bb">
						<v-col class="text-uppercase" cols="6" v-text="skill.name" />

						<v-col class="text-right" cols="6" v-text="`${skill.value}%`" />

						<v-progress-linear :value="skill.value" color="info" height="8" />
					</v-row>
				</v-alert>
			</v-col>

			<v-col class="hidden-sm-and-down" md="6">
				<v-img :src="require('../assets/aboutme.png')" height="100%" />
			</v-col>
		</v-row>
	</section>
</template>

<script>
export default {
	data: () => ({
		skills: [
			{
				name: "Web Design",
				value: 100,
			},
			{
				name: "Web Development",
				value: 75,
			},
			{
				name: "Web Support",
				value: 90,
			},
		],
	}),
};
</script>
