<template>
	<div>
		<v-card> hallo </v-card>
	</div>
</template>

<script>
export default {
	name: "Home",
};
</script>
