import Vue from 'vue';
import App from './App.vue';
import '@shared/apis/registerServiceWorker';
import router from './router';
import store from './store';
//import globalStore from '@shared/store/'
import vuetify from './plugins/vuetify';
import i18n from '@shared/apis/i18n';

Vue.config.productionTip = false;

router.beforeEach((to, from, next) => {
    let language = navigator.language;
    if (!language) {
        language = 'de';
    }

    i18n.locale = language;
    next();
});

new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: h => h(App),
}).$mount('#app');