<template>
	<div>
		<welcome />

		<recent-projects />

		<services />

		<about-me />

		<get-in-touch />
	</div>
</template>

<script>
export default {
	name: "CoreView",

	components: {
		AboutMe: () => import("../AboutMe"),
		GetInTouch: () => import("../GetInTouch"),
		RecentProjects: () => import("../RecentProjects"),
		Services: () => import("../Services"),
		Welcome: () => import("../Welcome"),
	},
};
</script>
