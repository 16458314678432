<template>
	<v-app style="width: 100vw">
		<v-sheet :dark="$vuetify.theme.dark" tile height="100%" width="100%">
			<!-- <AppBar></AppBar> -->
			<!-- <v-main>
				<keep-alive>
					<router-view :key="$route.fullPath"></router-view>
				</keep-alive>
			</v-main> -->
			<!-- <Footer></Footer> -->
			<core-app-bar />

			<core-view />

			<core-footer />
		</v-sheet>
	</v-app>
</template>

<script>
// import AppBar from "./components/AppBar";
// import Footer from "./components/Footer";

export default {
	name: "App",
	components: {
		CoreAppBar: () => import("./components/core/AppBar"),
		CoreFooter: () => import("./components/core/Footer"),
		CoreView: () => import("./components/core/View"),

		// AppBar,
		// Footer,
	},

	created() {
		if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
			this.$vuetify.theme.dark = true;
		} else {
			this.$vuetify.theme.dark = false;
		}
	},
};
</script>

<style>
/* width */
::-webkit-scrollbar {
	width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #888;
	border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
	border-radius: 2px;
}
</style>
