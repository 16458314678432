import { render, staticRenderFns } from "./AboutMe.vue?vue&type=template&id=2390b3a9&"
import script from "./AboutMe.vue?vue&type=script&lang=js&"
export * from "./AboutMe.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.9.7_679359cdb69c218f2f8f476b2ba08796/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/.pnpm/vuetify-loader@1.7.2_6427b3c1f57fd496b0c914107314d8c8/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAlert,VCol,VImg,VProgressLinear,VRow})
